import React from 'react';

// Components
import Layout from '../components/Layout/Layout';
import BookList from '../components/BookList/BookList';
import SEO from '../components/SEO/seo';
import { booksRead } from '../components/BookList/bookData';

const Reading = () => {
  return (
    <Layout>
      <SEO title="Reading" />
      <h1>Reading</h1>
      <p>
        Reading is one of my favorite and most valued hobbies, and it is something I do every day. Between 2019 and 2022, I read almost exclusively fiction, but since dabbling back into fiction in 2023, I imagine I will be reading both this year. My reading goal in 2024 is 20 books.
      </p>
      {booksRead.map(books => (
        <BookList key={books.year} header={books.year} booksRead={books.books} />
      ))}
    </Layout>
  );
};

export default Reading;
