import React from 'react';
import PropTypes from 'prop-types';

// Components
import NumberCircle from './NumberCircle';
import Tag from './Tag';

const BookListItem = ({
  number,
  numbered,
  title,
  author,
  dateRead,
  audiobook,
  reread,
}) => (
  <div className="BookListItem">
    <span className="BookListItem__numbered-line">
      <NumberCircle number={number} show={numbered} />
      <em>{title}</em>
    </span>
    <div className={`BookListItem__date${numbered ? ' numbered' : ''}`}>
      by {author}&nbsp;
    </div>
    <div className={`BookListItem__date${numbered ? ' numbered' : ''}`}>
      {dateRead && <span>Completed {dateRead}</span>}
      {reread && <Tag className="reread" text="reread" />}
      {audiobook && <Tag className="audiobook" text="audiobook" />}
    </div>
  </div>
);

BookListItem.propTypes = {
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  dateRead: PropTypes.string,
  format: PropTypes.string,
};

BookListItem.defaultProps = {
  dateRead: '',
};

export default BookListItem;
