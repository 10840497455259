import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ text, className }) => (
  <span className={`Tag ${className}`}>{text}</span>
);

Tag.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Tag;
