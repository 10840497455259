import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import BookListItem from './BookListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const BookList = ({ header, booksRead }) => {
  const isDefaultExpanded = header === 'Currently reading' || header === '2024';
  const [expanded, setExpanded] = useState(isDefaultExpanded);

  const allowExpand = header === 'Currently reading' ? false : true;

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const renderBooksRead = () =>
    booksRead.map((book, index) => (
      <BookListItem
        key={index}
        number={booksRead.length - index}
        numbered={!isNaN(parseInt(header))}
        title={book.title}
        author={book.author}
        dateRead={book.dateRead}
        audiobook={book.audiobook}
        reread={book.reread}
      />
    ));

  if (!booksRead.length) return <p></p>;

  return (
    <p>
      <h2
        className={`BookList__header ${header}`}
        onClick={allowExpand ? toggleExpanded : null}
      >
        {header}
        {allowExpand ? (
          <CSSTransition
            in={expanded}
            timeout={300}
            classNames="BookList__chevron"
          >
            <FontAwesomeIcon
              size="xs"
              rotation={isDefaultExpanded && expanded ? 90 : 0}
              transform={`shrink-6 down-1 right-2`}
              icon={faChevronRight}
            />
          </CSSTransition>
        ) : null}
      </h2>
      <CSSTransition
        in={expanded}
        timeout={300}
        unmountOnExit
        classNames="BookList__list"
      >
        <div>{renderBooksRead()}</div>
      </CSSTransition>
    </p>
  );
};

export default BookList;
