import React from 'react';
import PropTypes from 'prop-types';

const NumberCircle = ({ number, show }) => (
  <div className={`NumberCircle${show ? '' : ' hide'}`}>{number}</div>
);

NumberCircle.propTypes = {
  number: PropTypes.number.isRequired,
};

export default NumberCircle;
